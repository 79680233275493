<template>
  <div class="commercial-illustration-page main">
    <div class="bg-white">
      <div class="ss-container">
        <h6 class="section-title text-black">Commercial Illustration</h6>
        <p class="section-desc text-black">商業插畫設計</p>
      </div>
    </div>
    <div class="bg-white white-section">
      <ListTemplate
        :data="commercialIllustrations"
        category="commercial-illustration"
      ></ListTemplate>
    </div>
  </div>
</template>

<script>
import ListTemplate from "@/components/ListTemplate.vue";
import CommercialIllustrations from "@/data/commercialIllustrations.js";
export default {
  name: "BusinessDesign",
  components: {
    ListTemplate
  },
  data() {
    return {
      commercialIllustrations: CommercialIllustrations
    };
  }
};
</script>

<style lang="sass" scoped>
.commercial-illustration-page
  padding-top: 130px
  background-color: #f9f9f9
  +deviceWidth()
    padding-top: 50px
.white-section
  padding-bottom: 48px
  +deviceWidth()
    padding-bottom: 30px
</style>
